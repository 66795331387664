header {
	.nav > li,
	.nav > li > a {
		position: static;
	}
	.dropdown-menu {
		width: 100%;
		.services-dropdown {
			padding: 15px;
			@include flexbox;
			.dropdown-category {
				width: 50%;
				> h3 {
					margin-top: 0;
					padding-left: 15px;
					@media screen and (max-width: 991px) {
						text-align: center;
					}
				}
				> div {
					max-height: 550px;
					@include flexbox;
					@include flex-wrap(wrap);
					@include flex-direction(column);
					@media screen and (max-width: 991px) {
						display: block;
						text-align: center;
						max-height: none;
					}
					.dropdown-service {
						width: 50%;
						padding: 10px 15px;
						@media screen and (max-width: 991px) {
							width: 100%;
							padding: 5px 15px;
						}
						> a {
							font-weight: bold;
						}
						> hr {
							margin: 5px 0 10px;
							@media screen and (max-width: 991px) {
								display: none;
							}
						}
						.dropdown-subservice {
							@media screen and (max-width: 991px) {
								display: none;
							}
							> a {
								display: block;
								padding-left: 10px;
							}
						}
					}
					a {
						color: #555;
					}
				}
			}
		}
	}
}