/* Typhography */

body {
    font-family: Arial, sans-serif;
}
img {
    max-width: 100%;
}


li {margin: 15px 0px 15px 0px; padding: 0px;}

img {
    margin: 0;
    padding: 0;
}

h1 {
    font-size: 39px;
    font-weight: 600;
	font-family: 'Montserrat', sans-serif;
}
h2 {
	font-size: 32px;
	color:#004D00;
	font-family: 'Montserrat', sans-serif;
}
h3 {
    font-size: 24px;
	color:#004D00;
	font-weight: bold;
	font-family: 'Montserrat', sans-serif;
}
h4 {
    font-size: 16px;
	font-weight: normal;
	font-family: 'Montserrat', sans-serif;
}
h5 {
    font-size: 31px;
    color: #231f20;
	font-family: 'Montserrat', sans-serif;
}
h6 {
    font-size: 22px;
    color: #000100;
	font-family: 'Montserrat', sans-serif;
}
p, ul, ol {
    font-size: 16px;
    color: #231f20
}
.container-fluid {
    padding: 0;
}
/* Main CSS Start */
/* Header CSS */

header {
    margin-top: 10px;
}
.logo1 {
    display: none;
}
nav {} nav ul {
    margin-top: 30px;
}
nav ul li {
    display: inline-block;
    list-style: outside none none;
    padding: 0px 14px;
}
nav ul li a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 22px;
    color: #000;
}
#active {
    color: #23527c;
}
nav ul li a:hover {
    text-decoration: none;
}
/* Slick nav */

.slicknav_menu {
    display: none;
}
.col-xs-push-12 {
    left: 0 !important;
}
/* Slider Area */

.carousel-caption {
    top: 0 !important;
	bottom: 0 !important;
	
    display: -webkit-box;
    display: -webkit-flex;
	display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;	
	align-items: center;
	-webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.carousel-caption > div {
	width: 100%;
	display: block;
	-webkit-box-flex: 0;
    -webkit-flex: none;
	flex: none;
}
.carousel-caption h1 {
	margin: 0;
    text-transform: uppercase;
    font-weight: bold;
}
.carousel-caption h3 {
	margin: 20px 0 0 0;
    font-weight: normal;
}
.carousel-inner > .item > img,
.carousel-inner > .item > a > img { top-border:7px solid #EC0404;
    line-height: 1;
    width: 100% !important;
}
/* Serving Content */

.serving-heading > h2 {
    margin: 40px 0;
}
.serving-content {
    margin: 45px 0;
}
.serving-content h4 {
    color: #004D00;
    font-weight: 600;
    margin: 15px 0;
}
.serving-content{
    border-right: 1px solid #adadb1;
}
.serving-content:last-child{
    border-right: 0px solid #adadb1;
}
#demo-border > img {
    margin-top: -15px;
}
#demo-border {
    margin-bottom: 5px;
}
/*.row.border-text::before {
    background: #adadb1 none repeat scroll 0 0;
    content: "";
    height: 140px;
    left: 37%;
    position: absolute;
    width: 1px;
    margin-top: 70px;
}
.row.border-text::after {
    background: #adadb1 none repeat scroll 0 0;
    content: "";
    height: 140px;
    right: 37%;
    position: absolute;
    width: 1px;
    margin-top: 70px;
}*/
/* Contact form */

section#mobile-form {
    display: none;
}
section#contact-area {
    background: #a7a8ac none repeat scroll 0 0;
}
.contact-text {
    margin: 50px 0;
    color: #fff;
}
.contact-text h4 {
    margin-top: 30px;
}
/* Tablet Layout */

.tablet-layout {
    display: none;
}
.form-control {
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 7px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    color: #000100 !important;
    display: block;
    font-size: 22px;
    height: 50px;
    line-height: 1.42857;
    padding: 6px 12px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 100%;
}
textarea.form-control {
    height: 117px;
}
#form-butn {
    background: #004D00 none repeat scroll 0 0;
    border: 1px solid #004D00;
    border-radius: 50px;
    color: #fff;
    font-size: 27px;
    margin-top: 15px;
    padding: 25px 10px;
    text-transform: capitalize;
}
.row.margin-txt {
    margin-bottom: 45px;
}
/* Service Content */

.service-heading h2 {
    margin: 45px 0;
}
.service-heading h4 {
    margin-bottom: 60px;
}
.service-content {
	height: 320px;
    margin-bottom: 30px;
}
.service-content a {
    color: #004D00;
    font-size: 26px;
    margin-bottom: 10px;
    text-decoration: none;
}
.service-content > a {
    color: #004D00;
    font-size: 24px;
}
/* Testimornial Area */

section#testimornial-area {
    background: #000 none repeat scroll 0 0;
    color: #fff;
    margin-top: 45px;
}
.container h1 {
    text-transform: uppercase;
}
.container h2 {
    margin-bottom: 15px;
    margin-top: 15px;
    text-transform: uppercase;
	font-weight: bold;
	color: #555;
}
.testimornial-content > h4 {
    margin-bottom: 55px;
    margin-top: 40px;
}
.testimornial-content > h4 {
    font-size: 25px;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 55px;
    margin-top: 40px;
}
/* Footer */

footer {
    margin: 10px 0;
}
footer h6 {
    margin-top: 15px;
}



header {
    margin:0;
    padding: 0 0 10px;
}

.navbar-inverse {
    background: #fff;
    border: none;
    margin-bottom:0;
}

.navbar-brand {
    margin-top: -15px;
    width: 80%;
    padding: 0;
	height: auto !important;
}

.navbar-brand > img {
	margin-top: 28px;
    margin-bottom: -20px;
    min-width: 250px;
}

.navbar-inverse .navbar-nav>li>a {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 21px;
    color: #000;
    text-shadow: none !important;
    text-align: center;
}

.navbar-inverse .navbar-nav>li>a:hover {
    color: #23527c;
}

.navbar-inverse .navbar-nav>li>a:focus {
	color: #000;
}

.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.open>a {
    background: transparent;
    box-shadow: none;
    color: #23527c;
}
.navbar-toggle {
    margin: 0;
    position: absolute;
    right: 15px;
    top: 22px;
	border: none;
}
.navbar-inverse .navbar-toggle .icon-bar {
    background-color: #000;
}
.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
    background-color: #ddd;
}
.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
    margin-top: 20px;
	border: none;
}
.navbar-inverse .navbar-nav > li > a:hover {
    background-color: #080808;
    color: #fff;
}

.heading-bar {
	background-color: black;
	margin-bottom: 15px;
}

.heading-bar h1 {
	color: white;
	font-family: 'Montserrat', sans-serif;
	text-transform: uppercase;
	margin: 10px 0;
	font-size: 22px;
	font-weight: normal;
	text-align: left;
	padding: 0 15px;
}

#testimornial-area h2 {
	color: white;
}

