@import "bootstrap";
@import "css/bootstrap-theme.min";
@import "fontawesome/font-awesome";
@import "css/main";
@import "css/style";
@import "css/responsive";

@import "bootstrap/variables";

@import "modules/flex";
@import "modules/header";
@import "modules/footer";
@import "modules/coupons";

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
	.navbar-inverse .navbar-nav>li>a {
		font-size: 14px;
	}
}

.call-button {
	z-index: 999;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50px;
	margin: 0;
	text-align: center;
	background-color: #fff;	
	a {
		display: block;
		font-size: 25px;
		font-style: normal;
		font-weight: 700;
		padding-top: 8px;
		padding-left: 50px;
		height: 50px;
		color: #000;
		text-transform: uppercase;
		box-shadow: 0 0 30px #000;
		&:hover,
		&:focus {
			text-decoration: none;
		}
		i {
			position: absolute;
			left: 0;
			bottom: 0;
			background: #000;
			color: #fff;
			font-size: 38px;
			padding-top: 7px;
			margin-right: 20px;
			width: 50px;
			height: 50px;
			text-align: center;
			vertical-align: text-bottom;
		}
	}
}

.side-contact-form {
	background-color: #f3f3f3;
	color: white;
	padding: 10px 10px 10px 10px;
	margin-bottom: 30px;
	a {
		color: #999 !important;
	}
	ul {
		color: #999;
		padding: 0;
		list-style: none;
		li {
			position: relative;
			margin: 0;
			border-top: 1px solid #ddd;
			padding: 7px 0 7px 45px;
			min-height: 36px;
			position: relative;
			&:first-child {
				border-top: none;
			}
			> ul {
				> li {
					padding-left: 20px;
				}
			}
			&.active {
				> a {
					color: black !important;
					font-weight: bold;
				}
			}
			> img {
				position: absolute;
				left: 0;
				top: 3px;
				width: 30px;
				height: 30px;
			}
			> .fa {
				position: absolute;
				top: 10px;
				left: 5px;
			}
		}
	}
	label {
		color: #999;
	}
	> strong {
		text-align: left;
		display: block;
		color: #0c83fb;
		margin: -10px -10px 10px -10px;
		padding: 10px;
		&:first-child {
			text-transform: uppercase;
			font-size: 150%;
			color: #555;
		}
		> a {
			color: #0c83fb !important;
		}
	}
	> #go-form {
		margin: 10px 0;
	}
	.form-control {
		font-size: 16px;
		height: 40px;
	}
	textarea.form-control {
		height: 200px;
	}
}

.text-content {
	> h1 {
		margin-top: 0;
	}
	.service-content-image {
		float: right;
		margin: 0 0 10px 25px;
		> img {
			width: 200px;
			height: auto;
		}
		@media screen and (max-width: $screen-md-min) {
			float: none;
			text-align: center;
			margin: 0 0 15px;
		}
	}
}

.btn-back {
	margin-top: 25px;
}

#testimornial-area {
	.quote-symbol {
		display: inline-block;
		background-color: #0c83fb;
		border-radius: 50%;
		width: 52px;
		height: 52px;
		.fa-quote-left {
			font-size: 30px;
			line-height: 52px;
		}
	}
}

.g-recaptcha-parent {
	margin-bottom: 15px;
	-webkit-transform-origin-x: 0;
	@media screen and (max-width: 767px) {
		transform: scale(0.96);
	}
}
.side-contact-form {
	.g-recaptcha-parent {
		transform: scale(0.77);
		@media screen and (max-width: 1199px) {
			transform: scale(0.61);
		}
		@media screen and (max-width: 991px) {
			transform: scale(1);
		}
		@media screen and (max-width: 767px) {
			transform: scale(0.79);
		}
	}
}