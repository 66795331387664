.footer-icons {
	padding-top: 20px;
	@include flexbox;
	@include justify-content(center);
	@include align-items(center);
	@include flex-wrap(wrap);
	> a {
		&:hover {
			text-decoration: none;
		}
	}
	.footer-icon {
		display: inline-block;
		background-image: url('../images/footer-icons.png');
		background-position-x: 0;
		background-repeat: no-repeat;
		background-size: auto;
		margin: 10px 20px;
	}
	.american-standard-icon {
		width: 160px;
		height: 115px;
		background-position-y: 0;
		background-size: 200%;
	}
	.well-mcclain-icon {
		width: 170px;
		height: 45px;
		background-position-y: -60px;
		background-size: 100%;
	}
	.mitusbishi-icon {
		width: 170px;
		height: 73px;
		background-position-y: -131px;
		background-size: 125%;
	}
}

footer {
	@media screen and (max-width: 767px) {
		margin-bottom: 50px;
	}
	.copyright {
		> div {
			float: right;
			@media screen and (max-width: 767px) {
				text-align: center;
				float: none;
			}
			> a {
				display: inline-block;
				margin-left: 10px;
				margin-bottom: 15px;
				text-align: center;
				&.instagram-link,
				&.google-link,
				&.facebook-link {
					width: 44px;
				}
				&.instagram-link {
					background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
					border-radius: 10px;
				}
				.fa {
					text-align: center;
					font-size: 50px;
					line-height: 44px;
					vertical-align: middle;
					&.fa-instagram {
						color: #fff;
						font-size: 38px;
					}
					&.fa-facebook-square {
						color: #3b5998;
					}
					&.fa-google-plus-square {
						color: #dc4e41;
					}
				}
				img {
					width: 116px;
					height: 44px;
				}
			}
		}
	}
}