@media only screen and (min-width: 1200px) {
	
	.carousel-caption h1 {
		font-size: 50px;
	}
	.carousel-caption h3 {
		font-size: 30px;
	}
}

/*    Small Desktop Layout: 1024px */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    body {} h1 {
        font-size: 27px;
    }
    p, ul, ol {
        font-size: 16px;
    }
    nav ul {
        margin-top: 18px;
    }
    nav ul li {
        padding: 0 13px;
    }
    .serving-content h4 {
        font-size: 26px;
    }
    .serving-content-last > h4 {
        font-size: 28px;
    }
    .contact-text h4 {
        font-size: 30px;
    }
    footer h6 {
        font-size: 20px;
    }
}
/*    Tablet Layout: 768px */

@media only screen and (min-width: 768px) and (max-width: 991px) {
    body {} .main-menu {
        display: none;
    }
    .slicknav_menu {
        display: block;
    }
    .slicknav_menu {
        background: none;
    }
    .slicknav_menu {
        display: block;
        float: right;
        margin-top: 20px;
    }
    .slicknav_btn {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border-radius: 4px;
        margin: -5px 30px 6px;
        text-decoration: none;
        text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    }
    .slicknav_nav a {
        color: #0c83fb;
        margin: 2px 5px;
        padding: 5px 10px;
        text-decoration: none;
        text-transform: uppercase;
    }
    .slicknav_menu .slicknav_menutxt {
        display: none;
        float: left;
        line-height: 1.188em;
    }
    .slicknav_menu .slicknav_icon-bar {
        background-color: #0c83fb;
        height: 4px;
        margin: 5px 0;
        padding: 0;
        width: 35px;
    }
    h1 {
        font-size: 40px;
        font-weight: 600;
    }
    h2 {
        font-size: 34px;
    }
    p, ul, ol {
        font-size: 16px;
    }
    h3 {
        font-size: 28px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 20px;
    }
    .serving-content h4 {
        margin: 5px 0;
    }
    .serving-content{
        border-right: 2px solid #adadb1;
    }
    .desktop-layout {
        display: none;
    }
    .tablet-layout {
        display: block;
    }
    .form-control {
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 7px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
        color: #000100 !important;
        display: block;
        font-size: 16px;
        height: 40px;
        line-height: 1.42857;
        padding: 6px 12px;
        transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
        width: 100%;
    }
    textarea.form-control {
        height: 97px;
    }
    #form-butn {
        background: #0c83fb none repeat scroll 0 0;
        border: 1px solid #0c83fb;
        border-radius: 50px;
        color: #fff;
        font-size: 16px;
        margin-top: 20px;
        padding: 15px 10px;
        text-transform: capitalize;
    }
    footer {
        margin: 25px 0;
    }
    footer h6 {
        font-size: 15px;
    }
	.navbar-brand {
	    width: 35%;
	}
	.navbar-inverse .navbar-nav > li > a {
		;
	}
}
/*    Mobile Layout: 320px */

@media only screen and (max-width: 767px) {
    body {} .main-menu {
        display: none;
    }
    h1 img {
    	width: 256px;
    }
    .slicknav_menu {
        display: block;
        background: none;
        float: right;
        text-align: center;
    }
    .slicknav_btn {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border-radius: 4px;
        margin: -5px 13px 6px;
        text-decoration: none;
        text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    }
    .slicknav_nav a {
        color: #0c83fb;
        margin: 10px 0;
        padding: 5px 10px;
        text-decoration: none;
        text-transform: uppercase;
    }
    .slicknav_menu .slicknav_menutxt {
        display: none;
        float: left;
        line-height: 1.188em;
    }
    .slicknav_menu .slicknav_icon-bar {
        background-color: #0c83fb;
        height: 4px;
        margin: 10px 0 5px;
        padding: 0;
        width: 30px;
    }
    h1 {
        font-size: 22px;
    }
    h2 {
        font-size: 19px;
    }
    p, ul, ol {
        font-size: 14px;
    }
    h3 {
        font-size: 16px;
    }
    h4 {
        font-size: 20px;
    }
    h5 {
        font-size: 20px;
    }
    .desktop-layout {
        display: none;
    }
    .tablet-layout {
        display: block;
    }
    .carousel-caption {
        top: 15%;
    }
    .carousel-caption h1 {
        margin-bottom: 5px;
    }
    section#serving-area {
        /*display: none;*/
    }
    section#contact-area {
        /*display: none;*/
    }
    section#mobile-form {
        display: block;
        background: #a7a8ac none repeat scroll 0 0;
    }
    .contact-text {
        color: #fff;
        margin: 20px 0;
    }
    .contact-text h2 {
        margin: 0;
        padding: 0;
        font-size: 20px;
    }
    .form-control {
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 7px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
        color: #000100 !important;
        display: block;
        font-size: 14px;
        height: 30px;
        line-height: 1.42857;
        margin-top: 0px;
        padding: 6px 8px;
        transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
        width: 100%;
    }
    .container h2 {
        margin-bottom: 15px;
        margin-top: 20px;
    }
    .service-heading h4 {
        /*display: none;*/
    }
 	.service-content {
        height: auto;
    }
	.service-content > img {
        margin: 15px 0;
    }
    .service-content > a {
        display: block;
        font-size: 18px;
    }
    .service-content br {
        display: none;
    }
    .testimornial-content > h4 {
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        margin-bottom: 55px;
        margin-top: 15px;
    }
    footer {
        margin: 25px 0;
    }
    footer h6 {
        font-size: 15px;
    }
    .copyright {
        margin-bottom: 20px;
    }	
	.navbar-brand {
	    width: 35%;
		padding-left: 10px;
	}
}
/*    Wide Mobile Layout: 480px  */

@media only screen and (min-width: 480px) and (max-width: 767px) {
    body {} h1 {
        font-size: 25px;
    }
    h3 {
        font-size: 18px;
    }
    .carousel-caption {
        top: 20%;
    }
    .testimornial-content > h4 {
        font-size: 20px;
    }
}